import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import IconButton from "@material-ui/core/IconButton"
import LinkedIn from "@material-ui/icons/LinkedIn"
import Image from "../Image"
import HostsQuery from "./HostsQuery"
import React from "react"
import { Typography } from "@material-ui/core"

const HostsDetails = ({ hosts }) => {
  return (
    <>
      {hosts.map(host => (
        <Box mb={2} key={host.name}>
          <Card>
            <CardHeader
              avatar={
                <Avatar>
                  <Image
                    imageInfo={{
                      image: host.headShot,
                      alt: host.name,
                    }}
                  />
                </Avatar>
              }
              title={<Typography>{host.name}</Typography>}
              classes={{
                action: "mt-0 mr-0",
              }}
              action={
                host.linkedIn ? (
                  <IconButton component="a" href={host.linkedIn}>
                    <LinkedIn />
                  </IconButton>
                ) : null
              }
            />
            <CardMedia>
              <Image
                imageInfo={{
                  image: host.image,
                  alt: host.name,
                }}
              />
            </CardMedia>
            {host.text ? (
              <CardContent>
                <Typography>{host.text}</Typography>
              </CardContent>
            ) : null}
          </Card>
        </Box>
      ))}
    </>
  )
}

export default () => (
  <HostsQuery render={hosts => <HostsDetails hosts={hosts} />} />
)
