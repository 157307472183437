import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { Footer, FooterLinks } from "../components/Layout/Footer"
import HostDetails from "../components/Hosts/Details"
import Layout from "../components/Layout"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

export const AboutTemplate = ({ title, embedSrc, html }) => {
  return (
    <Layout>
      <SEO title={title} />
      <Container style={{ paddingBottom: "20vh", paddingTop: 16 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
          <Box position="sticky" top={0}>
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
            <Box
              position="relative"
              style={{ paddingTop: "56.5%", backgroundColor: "#000" }}
              mb={2}
            >
              <iframe
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  right: 0,
                }}
                title={title}
                src={embedSrc}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
            <div dangerouslySetInnerHTML={{ __html: html }} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box position="sticky" top={0}>
              <Typography variant="h4" gutterBottom>
                Your Hosts
              </Typography>
              <HostDetails />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
      <FooterLinks />
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPath($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        embedSrc
      }
    }
  }
`

export default ({ data }) => {
  return <AboutTemplate html={data.markdownRemark.html} {...data.markdownRemark.frontmatter} />
}
